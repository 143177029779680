@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #edf1f7 !important;
}

.ant-menu.ant-menu-vertical.ant-menu-sub {
  background: #edf1f7 !important;
}

.ant-table-footer,
.ant-table-thead > tr > th {
  background: #edf1f7 !important;
}

html {
  --antd-wave-shadow-color: #455464;
}

::selection {
  color: #fff;
  background: #455464;
}

* {
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

a {
  color: #455264;
}

a:hover {
  color: #87be4b;
}

.ant-btn {
  border-radius: 8px !important;
}

.default-border-radius {
  border-radius: 8px !important;
}

.ant-btn:hover,
.ant-btn:active,
.ant-btn:focus {
  border-color: #4689af;
  color: #4689af;
  border-radius: 8px !important;
}

.ant-btn-primary {
  background-color: #87be4b;
  color: #ffffff;
  border-color: #87be4b;
  text-shadow: unset;
  box-shadow: unset;
  border-radius: 8px !important;
}

.ant-btn-primary:hover,
.ant-btn-primary:active,
.ant-btn-primary:focus {
  background-color: #a5d373;
  color: #ffffff;
  border-color: #a5d373;
}

.secondary-button {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 4px 15px;
  height: 100%;
}

.secondary-button:hover,
.secondary-button:active,
.secondary-button:focus {
  border-color: #4689af;
  color: #4689af;
}

.ant-pagination-item-active {
  color: #4689af !important;
  border-color: #4689af !important;
}

.ant-pagination-item-active a {
  color: #4689af !important;
}

.ant-pagination-item:hover {
  color: #4689af !important;
  border-color: #4689af !important;
}

.ant-pagination-item a:hover {
  color: #4689af !important;
}

.ant-pagination-next:hover {
  border-color: #4689af !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  color: #4689af !important;
  border-color: #4689af !important;
  background-color: #4689af !important;
}

.ant-input:focus,
.ant-input:hover {
  border-color: #4689af !important;
}

.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus {
  border-color: #4689af !important;
}

.ant-input-affix-wrapper-focused {
  border-color: #4689af !important;
}

.ant-select-selector:hover,
.ant-select-selector:focus,
.ant-select-selector:active {
  border-color: #4689af !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: #4689af !important;
}

li.ant-menu-item.ant-menu-item-selected > span > span.anticon.sidebar-icon {
  font-size: 22px;
  color: white !important;
  fill: white !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #4689af !important;
}

li.ant-pagination-next > button:hover {
  color: #4689af !important;
  fill: #4689af !important;
  border-color: #4689af !important;
}

li.ant-pagination-prev > button:hover {
  color: #4689af !important;
  fill: #4689af !important;
  border-color: #4689af !important;
}

li.ant-pagination-prev.ant-pagination-disabled > button:hover {
  color: rgba(0, 0, 0, 0.25) !important;
  fill: rgba(0, 0, 0, 0.25) !important;
  border-color: rgba(0, 0, 0, 0.25) !important;
}

.ant-menu-dark .ant-menu-submenu-selected {
  color: #87be4b !important;
}

.ant-menu-dark
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title
  > .anticon
  > svg {
  fill: #87be4b !important;
}

.ant-menu-dark .ant-menu-submenu-open {
  color: black !important;
}

.ant-menu-dark
  .ant-menu-submenu-open
  > .ant-menu-submenu-title
  > .anticon
  > svg {
  fill: black !important;
}

.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-submenu-title:hover {
  color: #87be4b !important;
}

.ant-menu-submenu-arrow::after,
.ant-menu-submenu-arrow::before {
  background: black !important;
}

.ant-menu-submenu-title .anticon {
  color: #455264 !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #4689af !important;
}

.ant-tabs-ink-bar {
  background: #4689af !important;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-label {
  width: calc(40% - 12px);
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail {
  left: 40%;
}

.ant-timeline.ant-timeline-alternate
  .ant-timeline-item-left
  .ant-timeline-item-content,
.ant-timeline.ant-timeline-label
  .ant-timeline-item-left
  .ant-timeline-item-content,
.ant-timeline.ant-timeline-right
  .ant-timeline-item-left
  .ant-timeline-item-content {
  left: calc(40% - 4px);
  width: calc(60% - 14px);
}

.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected {
  background-color: #87be4b !important;
}

/* @media (max-width: 767px) {
  .desktop-version {
    display: none;
  }
  .mobile-version {
    display: block;
  }
}

@media (min-width: 768px) {
  .desktop-version {
    display: table;
  }
  .mobile-version {
    display: none;
  }
} */
.ant-card-body {
  padding: 20px 10px 20px;
}
.nz-card {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: box-shadow 0.3s ease-in-out;
  margin-top: 10px;
}
.nz-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2), 0 12px 40px rgba(0, 0, 0, 0.2);
}

.ant-table-container,
.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 8px;
}

nz-content {
  min-height: 100vh !important;
}

.box {
  border: 1px solid #edf1f7;
  border-radius: 8px;
  width: 100% !important;
}

.disabled-input {
  cursor: not-allowed;
}

.full-width {
  width: 100%;
}

.edit-field {
  min-width: 100%;
}

.ant-input {
  border-radius: 8px !important;
  border: unset !important;
}

.save-button:hover {
  border: unset !important;
}

.cancel-button:hover {
  border: unset !important;
}

.underline {
  text-decoration: underline;
}

.loader-container {
  margin-top: 45vh;
  z-index: 9999;
  height: 100vh;
}

.center {
  text-align: center;
}

.element-detail-row {
  margin-bottom: 0.5rem;
}

.element-details-title{
  margin-bottom: 0;
}

.details-container {
  margin-top: 1rem;
}

.action-buttons {
  border: unset;
  background: transparent;
  box-shadow: unset;
}

.action-buttons:hover{
  background: transparent;

}
